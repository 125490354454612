@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@500&display=swap");

body {
  /* background: url('./assets/images/background-griffos.png');
  background-repeat: no-repeat;
  background-size: cover; */
  margin: 0;
  font-family: "Space Grotesk";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .MuiButton-contained.Mui-disabled {
  color: #fff !important;
} */