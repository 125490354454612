.header {
    background-color: #110A0A;
    color: #fff;
    height: 95px;
    display: flex;
    padding: 20px 120px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    top: 0;
}

.header-title {
    font-size: 33px;
}