.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #110A0A;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer img {
    height: 100px;
    width: 100px;
}