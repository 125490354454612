@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@500&display=swap);
.home-container {
  padding: 95px 120px 180px;
  height: 100%;
}

.griffos-container {
  padding-bottom: 10px;
  background-color: #151a1f;
  border-radius: 6px;
  text-align: center;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  color: #fff;
}

.griffos-banner {
  background: linear-gradient(180deg, transparent, #000),
    url(/static/media/maximo-banner.0a9c2da4.png)
      ;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  width: 100%;
  height: 350px;
}

.griffos-body {
  background-color: #39354e;
  padding: 50px;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  grid-gap: 100px;
  gap: 100px;
}

.griffos-body-left {
  flex: 1 1 60%;
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  grid-gap: 15px;
  gap: 15px;
  grid-gap: 15px;
}

.griffos-body-right {
  flex: 1 1 50%;
  position: relative;
}

.griffos-container h1 {
  font-size: 64px;
  line-height: 80px;
  margin: 0;
}

.griffos-container p {
  font-size: 20px;
  margin: 0;
}

.griffos-container ul {
  list-style: none;
  display: flex;
  padding: 0;
  grid-gap: 40px;
  gap: 40px;
  margin: 0;
}

.griffos-body-right > div {
  position: absolute;
  top: -135px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (min-width: 769px) {
  .griffos-body-right > div {
    width: 400px;
    height: 400px;
    margin-bottom: 400px;
    background-position: center;
    background-size: cover;
  }
}

.griffos-body-right img {
  width: 385px;
  height: 437px;
  margin-bottom: 40px;
}

.progress-done {
  position: absolute;
  color: #fff;
  font-size: 18px;
  line-height: 18px;
  height: 18px;
  width: 50px;
  left: 0;
  bottom: 20px;
}

.progress-total {
  position: absolute;
  color: #fff;
  font-size: 18px;
  line-height: 18px;
  height: 18px;
  width: 50px;
  right: 0;
  bottom: 20px;
}

.MuiLinearProgress-bar {
  border-radius: 15px;
}

.MuiLinearProgress-colorSecondary {
  background-color: #fff !important;
}

.griffos-body-right .MuiLinearProgress-root {
  width: 100%;
}

@media screen and (max-width: 769px) {
  .home-container {
    padding: 0;
  }

  .griffos-body {
    flex-wrap: wrap;
    padding: 24px;
    grid-gap: 150px;
    gap: 150px;
    padding-bottom: 50px;
  }

  .griffos-container {
    padding-bottom: 130px;
  }

  .griffos-container h1 {
    font-size: 50px;
    line-height: 50px;
  }

  .griffos-container p {
    font-size: 14px;
  }

  .griffos-body-left {
    order: 1;
  }

  .griffos-body-right {
    order: 0;
  }

  .griffos-body-right img {
    width: 192px;
    height: 213px;
  }

  .griffos-banner {
    background-size: 100% 70%;
    height: 330px;
  }

  .griffos-body-right > div {
    top: -150px;
  }
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #110A0A;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer img {
    height: 100px;
    width: 100px;
}
.header {
    background-color: #110A0A;
    color: #fff;
    height: 95px;
    display: flex;
    padding: 20px 120px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    top: 0;
}

.header-title {
    font-size: 33px;
}
.main-container {
    background-color: #14152A;
    min-height: 100vh;
    position: relative;
}
body {
  /* background: url('./assets/images/background-griffos.png');
  background-repeat: no-repeat;
  background-size: cover; */
  margin: 0;
  font-family: "Space Grotesk";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .MuiButton-contained.Mui-disabled {
  color: #fff !important;
} */
