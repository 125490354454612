.home-container {
  padding: 95px 120px 180px;
  height: 100%;
}

.griffos-container {
  padding-bottom: 10px;
  background-color: #151a1f;
  border-radius: 6px;
  text-align: center;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  color: #fff;
}

.griffos-banner {
  background: linear-gradient(180deg, transparent, #000),
    url("../assets/images/maximo-banner.png")
      /* , linear-gradient(180deg, rgba(137, 122, 227) 0%, #08041B 95.76%) */;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  width: 100%;
  height: 350px;
}

.griffos-body {
  background-color: #39354e;
  padding: 50px;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  gap: 100px;
}

.griffos-body-left {
  flex: 1 1 60%;
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  grid-gap: 15px;
}

.griffos-body-right {
  flex: 1 1 50%;
  position: relative;
}

.griffos-container h1 {
  font-size: 64px;
  line-height: 80px;
  margin: 0;
}

.griffos-container p {
  font-size: 20px;
  margin: 0;
}

.griffos-container ul {
  list-style: none;
  display: flex;
  padding: 0;
  gap: 40px;
  margin: 0;
}

.griffos-body-right > div {
  position: absolute;
  top: -135px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (min-width: 769px) {
  .griffos-body-right > div {
    width: 400px;
    height: 400px;
    margin-bottom: 400px;
    background-position: center;
    background-size: cover;
  }
}

.griffos-body-right img {
  width: 385px;
  height: 437px;
  margin-bottom: 40px;
}

.progress-done {
  position: absolute;
  color: #fff;
  font-size: 18px;
  line-height: 18px;
  height: 18px;
  width: 50px;
  left: 0;
  bottom: 20px;
}

.progress-total {
  position: absolute;
  color: #fff;
  font-size: 18px;
  line-height: 18px;
  height: 18px;
  width: 50px;
  right: 0;
  bottom: 20px;
}

.MuiLinearProgress-bar {
  border-radius: 15px;
}

.MuiLinearProgress-colorSecondary {
  background-color: #fff !important;
}

.griffos-body-right .MuiLinearProgress-root {
  width: 100%;
}

@media screen and (max-width: 769px) {
  .home-container {
    padding: 0;
  }

  .griffos-body {
    flex-wrap: wrap;
    padding: 24px;
    gap: 150px;
    padding-bottom: 50px;
  }

  .griffos-container {
    padding-bottom: 130px;
  }

  .griffos-container h1 {
    font-size: 50px;
    line-height: 50px;
  }

  .griffos-container p {
    font-size: 14px;
  }

  .griffos-body-left {
    order: 1;
  }

  .griffos-body-right {
    order: 0;
  }

  .griffos-body-right img {
    width: 192px;
    height: 213px;
  }

  .griffos-banner {
    background-size: 100% 70%;
    height: 330px;
  }

  .griffos-body-right > div {
    top: -150px;
  }
}
